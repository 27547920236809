import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/SEO"

import Container from "../../components/Container/Container"
import Product from "../../components/Product/Product"
import ProductHeader from "../../components/Product/ProductHeader"
import ProductDescription from "../../components/Product/ProductDescription"
import ProductDocs from "../../components/Product/ProductDocs"

// import headerImage from "../../images/products/drycooler.jpg"
import ProductDocsList from "../../components/Product/ProductDocsList"
import ProductAccordionListItem from "../../components/Product/ProductAccordion/ProductAccordionListItem"

const DrycooleryLuVe = () => {
  const { drycooler } = useStaticQuery(
    graphql`
      query {
        drycooler: file(relativePath: { eq: "products/drycooler.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 720) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  return (
    <Layout pageInfo={{ pageName: "drycoolery-lu-ve" }}>
      <SEO title="Drycoolery LU-VE" />
      <Container>
        <Product>
          <ProductHeader headerImage={drycooler.childImageSharp.fluid}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
            rutrum tempor efficitur. Aenean tempus risus fermentum justo
            scelerisque condimentum. Praesent est diam, molestie facilisis ante
            a, posuere ultricies ex.
          </ProductHeader>
          <ProductDocs>
            <ProductDocsList title="Karty katalogowe">
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-14.pdf">
                AlfaNova 14
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-27.pdf">
                AlfaNova 27
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-52.pdf">
                AlfaNova 52
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-76.pdf">
                AlfaNova 76
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/products/heat-transfer/plate-heat-exchangers/fusion-bonded-plate-heat-exchangers/alfanova-200_productleaflet_che00135en.pdf">
                AlfaNova 200
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-400.pdf">
                AlfaNova 400
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Instrukcje">
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-14.pdf">
                Instrukcja obsługi 1
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-27.pdf">
                Instrukcja 2
              </ProductAccordionListItem>
            </ProductDocsList>
            <ProductDocsList title="Deklaracje zgodności i atesty">
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-14.pdf">
                Deklaracje AlfaNova
              </ProductAccordionListItem>
              <ProductAccordionListItem linkTo="https://www.alfalaval.com/globalassets/documents/local/poland/ecf/alfa-laval-alfanova-14.pdf">
                Atesty AlfaNova
              </ProductAccordionListItem>
            </ProductDocsList>
          </ProductDocs>
          <ProductDescription>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              rutrum tempor efficitur. Aenean tempus risus fermentum justo
              scelerisque condimentum. Praesent est diam, molestie facilisis
              ante a, posuere ultricies ex. Vivamus pulvinar sed nulla vel
              rutrum. Curabitur in velit tempor, tincidunt lectus ac, rutrum
              purus. Fusce erat nulla, venenatis nec tincidunt in, imperdiet in
              nisi. Vestibulum a malesuada nunc, nec ullamcorper tellus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              rutrum tempor efficitur. Aenean tempus risus fermentum justo
              scelerisque condimentum. Praesent est diam, molestie facilisis
              ante a, posuere ultricies ex. Vivamus pulvinar sed nulla vel
              rutrum. Curabitur in velit tempor, tincidunt lectus ac, rutrum
              purus. Fusce erat nulla, venenatis nec tincidunt in, imperdiet in
              nisi. Vestibulum a malesuada nunc, nec ullamcorper tellus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              rutrum tempor efficitur. Aenean tempus risus fermentum justo
              scelerisque condimentum. Praesent est diam, molestie facilisis
              ante a, posuere ultricies ex. Vivamus pulvinar sed nulla vel
              rutrum. Curabitur in velit tempor, tincidunt lectus ac, rutrum
              purus. Fusce erat nulla, venenatis nec tincidunt in, imperdiet in
              nisi. Vestibulum a malesuada nunc, nec ullamcorper tellus.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris
              rutrum tempor efficitur. Aenean tempus risus fermentum justo
              scelerisque condimentum. Praesent est diam, molestie facilisis
              ante a, posuere ultricies ex. Vivamus pulvinar sed nulla vel
              rutrum. Curabitur in velit tempor, tincidunt lectus ac, rutrum
              purus. Fusce erat nulla, venenatis nec tincidunt in, imperdiet in
              nisi. Vestibulum a malesuada nunc, nec ullamcorper tellus.
            </p>
          </ProductDescription>
        </Product>
      </Container>
    </Layout>
  )
}

export default DrycooleryLuVe
